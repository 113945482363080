import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import SEO from '../components/SEO.js';
import AppTheme from '../AppTheme.js';
import RageFace from '../components/RageFace.js';

const TheWhoaJess = () => (
  <>
    <CssBaseline />
    <ThemeProvider theme={AppTheme}>
      <SEO title="thewhoajess" description="she's mad." />
      <RageFace />
    </ThemeProvider>
  </>
);

export default TheWhoaJess;
