import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    width: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundColor: theme.palette.common.rage1,
    backgroundImage: `url(${require('../images/rage.svg')})`,
  },
}));

const RageFace = () => {
  const classes = useStyles();
  return (
    <div className={classes.root} />
  );
};

export default RageFace;